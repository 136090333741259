<template>
  <div>
    <a-drawer
      :title="formTitle"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="close"
    >
      <div class="drawer-content" v-if="formType !== 3">
        楼栋信息
        <a-divider></a-divider>
        <a-form-model
          layout="vertical"
          ref="ruleForm"
          :model="form"
          :rules="rules"
        >
          <a-form-model-item label="楼栋号" prop="name">
            <a-input
              v-model="form.name"
              placeholder="请输入楼栋号"
              style="width: 50%"
            />
          </a-form-model-item>
          <a-form-model-item
            label="上传照片"
            style="width: 70%"
            prop="fileList"
          >
            <a-upload
                :action="`${$upload}`"
                :headers="uploadHeaders"
                accept='.jpg,.JPG,.png,.PNG'
                list-type="picture"
                :file-list="fileList"
                @preview="handlePreview"
                @change="fileChange"
				:beforeUpload='beforeUpload'
            >
              <a-button> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-model-item>
        </a-form-model>
      </div>
      <!-- 详情 -->
      <div class="drawer-content detail" v-else-if="formType===3">
        楼栋信息
        <a-divider></a-divider>
        <a-form layout="vertical">
          <a-form-item label="楼栋号" prop="name">
            <span>{{form.name}}</span>
          </a-form-item>
          <a-form-item label="楼栋照片" style="width: 70%" v-if="fileList.length>0">
              <a-upload
                :action="`${$upload}`"
                :headers="uploadHeaders"
                accept='.jpg,.JPG,.png,.PNG'
                list-type="picture"
                :file-list="fileList"
                @preview="handlePreview"
                @change="fileChange"
				:beforeUpload='beforeUpload'
            >
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-form>
        单元信息
        <a-divider></a-divider>
        <a-button class='add-btn' @click='addUnit' style="margin-bottom:5px">新增单元</a-button>
		<a-upload name="file" :action="`${$unitUpload}`"
			accept=".xls,.XLS,.xlsx,.XLSX" :showUploadList="false"
			:headers="uploadHeaders" @change="changeFile">
			<a-button>批量导入</a-button>
		</a-upload>
		<a-button @click="modelDownload">
			模版下载
		</a-button>
        <a-table :columns="unitColumns" :data-source="unitData" :row-key="record => record.id">
          <span slot="action" slot-scope="text, row">
            <a-space><a @click="addUnit(text, row)">编辑</a>
            <a @click="delUnit(text, row)" style="color: red">删除</a></a-space>
          </span>
        </a-table>
      </div>
      <!-- foot -->
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="close">
          关闭
        </a-button>
        <a-button type="primary" @click="submit" v-if="formType !==3">
          提交
        </a-button>
        <a-button type="primary" @click="edit" v-else> 编辑 </a-button>
      </div>
    </a-drawer>
	<!-- 单元 -->
	<a-modal
      :title="unit.modalTitle"
      :visible="unit.showModal"
      @ok="unitSubmit"
      @cancel="unitClose"
    >
    	<div>
			<a-form-model
				ref="unForm"
				:model="unit.unitForm"
				:rules="unit.rules"
				layout="vertical"
			>
			<a-form-model-item prop='name' label='单元号'>
				<a-input placeholder='请输入' v-model="unit.unitForm.name"/>
			</a-form-model-item>
			<a-form-model-item prop='floor' label='楼层数'>
				<a-input placeholder='请输入' v-model.number="unit.unitForm.floor"/>
			</a-form-model-item>
			<a-form-model-item prop='publicFloor' label='公共楼层数'>
				<a-input placeholder='请输入' v-model.number="unit.unitForm.publicFloor"/>
			</a-form-model-item>
			<a-form-model-item prop='elevator' label='是否有电梯'>
				<a-select placeholder='请选择' v-model="unit.unitForm.elevator">
					<a-select-option :value='1'>有</a-select-option>
					<a-select-option :value='0'>无</a-select-option>
				</a-select>
			</a-form-model-item>
			</a-form-model>
	  </div>
    </a-modal>
  </div>
</template>

<script>
import store from "@/store";
import { form, rules, unitColumns,uploadHeaders } from "./config";
import { buildInsert,buildUpdate, buildInfo, unitAdd, unitUpdate, unitDel } from '@/api/basic/estate'
function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}
const handleConfirm = (rule,value,callback) => {
	if(!value){
		callback();
	  }else{
		// 正则判断失败抛出错误，否则直接callback()
		if(/[\u4E00-\u9FA5]/g.test(value)) {
		  callback(new Error("不能输入中文！"));
		}else{
		  callback();
		}
	}
}
export default {
	props: {
		show: Boolean,
		type: Number,
		editId: Number,
	},
	data() {
		return {
			form,
			rules,
			unitColumns,
			uploadHeaders,
			previewVisible: false,
			previewImage: "",
			imgLoading: false,
			formTitle:'新增楼栋',
			fileList: [],   //图片list
			unitData: [], //单元数据
			formType: 1, //1 add 2edit 3detail
			unit:{// 单元
				showModal:false, //弹窗
				modalTitle:'',
				unitForm:{ //表单
					id:null,
					name:'',
					floor:null,
					elevator:undefined,
					publicFloor:null,
					manageBuildingId:null
				},

				rules:{
					name:[{required:true,message:'请输入单元名称',trigger:'blur'},{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
					floor:[{required:true,message:'请输入楼层数',trigger:'blur'},{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
					elevator:[{required:true,message:'是否有电梯',trigger:'change'}],
					publicFloor:[{required:true,message:'公共楼层数',trigger:'blur'},{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
				}
			},
		};
	},
	methods: {
		getInfo(){
			buildInfo({buildingId:this.form.id}).then(res=>{
				this.form.name =res.data.name
				this.unitData =res.data.manageUnitFBIVoList
				if(!res.data.buildingInfoImgList.length){
					this.fileList=[]
				}
				const file = []
				for(let item of res.data.buildingInfoImgList){
					let obj = {
						name:item.url.split('_')[0] +'.'+ item.url.split('.')[1],
						url: this.$ImgUrl(item.url),
						uid:item.url.split('_')[1],
						status:'done',
						thumbUrl: this.$ImgUrl(item.url),
					}
					file.push(obj)
				}
				this.fileList = file
			})
		},
		close() {// 楼栋drawer关闭的回调
			this.$emit('close');
			this.form = {
				id:null,
				name: '',
				buildingInfoImgUrls:[]
			}
			this.unitData =[]
			this.fileList = []
		},
		 edit(){// 楼栋修改
			this.formType = 2;
		},
		beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
		submit(){// 楼栋提交
			let file = []
			for(let item of this.fileList){
				// 新增的照片
				if(item.response){
					file.push(item.response.data)
				}else{
				// 已添加的照片
					file.push(item.url.split('/')[item.url.split('/').length-1])
				}
			}
			this.form.buildingInfoImgUrls = file
			this.$refs.ruleForm.validate(async valid => {
				if (valid) {
					if(this.formType ===1){
						const res =  await buildInsert(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.close()
						}else{
							this.$message.error(res.msg)
						}
					}else if(this.formType ===2){
						const res =  await buildUpdate(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.close()
						}else{
							this.$message.error(res.msg)
						}
					}

				}
			});
		},
		addUnit(data,row){//新增单元
			if(row!==undefined){
				this.unit.modalTitle='修改单元'
				this.unit.unitForm= {...row}
				this.unit.unitForm.manageBuildingId = this.form.id
			}else{
				this.unit.modalTitle='新增单元'
			}
			this.unit.showModal=true
		},
		unitSubmit(){//单元提交
			this.$refs.unForm.validate(async valid => {
				if (valid) {
					if(this.unit.modalTitle==='新增单元'){
						let res = await unitAdd(this.unit.unitForm)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.getInfo()
							this.unitClose()
						}else{
							this.$message.error(res.msg)
						}
					}else{
						let res = await unitUpdate(this.unit.unitForm)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.getInfo()
							this.unitClose()
						}else{
							this.$message.error(res.msg)
						}
					}
				}
			});
		},
		delUnit(data,row){
			this.$confirm({
				title: "是否删除",
				icon: "delete",
				onOk: async() => {
					let res  = await unitDel({unitIds:[row.id]})
					if(res.code === 200){
						this.$message.success(res.msg)
						this.getInfo()
					}else{
						this.$message.error(res.msg)
					}
				},
			});
		},
		unitClose(){
			this.unit.showModal = false;
			this.$refs.unForm.resetFields()
		},
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		handleCancel() {
			this.previewVisible = false;
		},
		fileChange(info) {
			this.fileList = info.fileList
		},
		changeFile(data) {
			if(data.file.status == "done") {
				if(data.file.response.success == false) {
					this.$message.error(data.file.response.msg);
				} else {
					this.$message.success('操作成功');
					this.getData();
				}
			}
		},
		//模版下载
		modelDownload() {
			window.open('https://saas.kaidalai.cn/excelModel/单元导入模版.xlsx');
		}
	},
	watch: {
		type: {
			handler(val) {
				this.formType = val;
			},
		},
		editId:{
			handler(val) {
				this.form.id = val;
				this.unit.unitForm.manageBuildingId = val
				if(this.formType !== 1){
					this.getInfo()
				}
			},
		},
		formType:{
			handler(val) {
				switch (val) {
				case 1:
					this.formTitle ='新增楼栋'
					break;
				case 2:
					this.formTitle ='修改楼栋'
					break;
				case 3:
					this.formTitle ='楼栋详情'
					break;
				default:
					break;
				}
			},
		}
	},
};
</script>

<style lang="less" scoped>
.detail{
    /deep/.anticon svg{
        display:none;
    }
}
</style>