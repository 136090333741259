/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "导入编号",
		dataIndex: "id",
		width: "8%",
	},
	{
		title: "楼栋名称",
		dataIndex: "name",
		width: "15%",
		customRender:function(name){
			return name + '栋'
		}
	},
	{
		title: "房屋总数",
		dataIndex: "estateTotals",
		width: "15%",
	},
	{
		title: "租赁房屋数",
		dataIndex: "leaseEstateNums",
		width: "15%"
	},
	{
		title: "租赁率",
		dataIndex: "leaseRate",
		width: "15%",
		customRender:function(leaseRate){
			return ( leaseRate*100 ).toFixed(2)+'%'
		}
	},
	{
		title: "入住率",
		dataIndex: "occupancyRate",
		width: "20%",
		customRender:function(occupancyRate){
			return ( occupancyRate*100 )+'%'
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
// 搜索项
export const searchForm = {
	name: "",
}
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
export const ActionsList = [
	{
		label: "批量删除",
		value: 1,
	},
]
export const form = {
	id:undefined,
	name: '',
	buildingInfoImgUrls:[]
}

const handleConfirm = (rule,value,callback) => {
	if(!value){
		callback();
	  }else{
		// 正则判断失败抛出错误，否则直接callback()
		if(/[\u4E00-\u9FA5]/g.test(value)) {
		  callback(new Error("不能输入中文！"));
		}else{
		  callback();
		}
	}
}

export const rules = {
	name:[{required:true,message:'请输入楼栋',trigger:'blur'},{validator: (rule,value,callback) => handleConfirm(rule,value,callback)}],
}

export const unitColumns = [
	{
		title: "导入编号",
		dataIndex: "id",
		width: "15%",
	},
	{
		title: "单元号",
		dataIndex: "name",
		width: "12%",
	},
	{
		title: "楼层数",
		dataIndex: "floor",
		width: "12%",
	},
	{
		title: "公共楼层数",
		dataIndex: "publicFloor",
		width: "18%",
	},
	{
		title: "是否有电梯",
		dataIndex: "elevator",
		width: "18%",
		customRender:function( elevator ){
			switch (elevator) {
			case 1:
				return  '有'
			case 0:
				return  '无'
			default:
				break;
			}
		}
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
import store from "@/store";
export const uploadHeaders = {
	"manage-login-token": store.getters.getToken,
}