<template>
  <div>
        <div class="cardTitle">楼栋管理</div>
        <div class="search-box">
          <a-row>
            <a-col :span='20'>
              <a-space size="large">
                <a-input v-model="searchForm.name" placeholder="请输入楼栋名称" />
                <a-button type="primary" @click='getData()'>查 询</a-button>
                <a-button @click='reset()'>重 置</a-button>
              </a-space>
            </a-col>
          </a-row>
        </div>
		<div class="search-box">
			<a-button class="add-btn"  @click="addBuilding">添加楼栋</a-button>
			<a-upload name="file" :action="`${$buildingUpload}`"
                    accept=".xls,.XLS,.xlsx,.XLSX" :showUploadList="false"
                	:headers="uploadHeaders" @change="changeFile">
                    <a-button><a-icon type="upload" /> 批量导入</a-button>
            </a-upload>
			<a-button @click="modelDownload">
				  <a-icon type="copy" />模版下载
			</a-button>
		</div>
        <a-table
          :columns="columns"
          :data-source="tableData"
		  :pagination="pagination"
		  @change="handleTableChange"
		  :row-selection="{
				selectedRowKeys: tableChoosed,
				onChange: selectionChoosed,
		  }"
		  :row-key="
			(record, index) => {
			return record.id;
			}"
        >
			<template slot="action" slot-scope="text,record">
				<a @click="detail(record.id)">详情</a>
				<a @click="del([record.id])" style="margin-left: 8px;color: red">删除</a>
			</template>
        </a-table>
		<div class="action">
		<a-dropdown :disabled="!hasSelected" >
			<a-menu slot="overlay" @click="handleMenuClick">
			<a-menu-item key="del"> 批量删除 </a-menu-item>
			</a-menu>
			<a-button> 批量操作 <a-icon type="down" /> </a-button>
		</a-dropdown>
		<span style="margin-left: 8px">
			<template v-if="hasSelected">
			{{ `已选择 ${tableChoosed.length} 条` }}
			</template>
		</span>
		</div>
		<curd-form :show='drawerConfig.show' :type="drawerConfig.type" @close='close' :editId='editId'></curd-form>
		<a-modal title="批量导入操作成功" :visible="uploadVisible" @cancel="uploadVisible = false" :footer="null">
			<div>以下为导入失败的用户</div>
			<p v-for="(item,index) in uploadResult" :key="index">
				{{item}}
			</p>
		</a-modal>
      </div>
</template>

<script>
import store from "@/store";
import { columns,searchForm,ActionsList} from "./depend/config"
import { buildingList,buildingDel } from "@/api/basic/estate"
import curdForm from './depend/form.vue'
export default {
	components: {
		curdForm
	},
	data() {
		return {
			pagination: {
				current: 1,
				total: 0,
				pageSize: 10,
				showTotal: (total) => `共 ${total} 条`,
				showSizeChanger: true,
				showQuickJumper: true,
			},
			columns,
			searchForm,
			ActionsList,
			drawerConfig:{
				type:1,
				show:false,
			},
			tableChoosed: [],
			//   数据
			tableData: [],
			editId:undefined,
			// 上传
			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
			//上传对话框
			uploadVisible: false,
			uploadResult: [],
		};
	},
	created() {
		this.getData()
	},
	computed: {
		hasSelected() {
			return this.tableChoosed.length > 0;
		},
	},
	methods: {
		async getData() {
			const res = await buildingList(
				{
					pageNum:this.pagination.current,
					size:this.pagination.pageSize,
					...this.searchForm
				}
			)
			this.tableData = res.data.rows
			this.pagination.total = res.data.total
		},
		addBuilding(){
			this.drawerConfig.show = true
			this.drawerConfig.type = 1
		},
		close(){
			this.drawerConfig.show = false
			this.drawerConfig.type = 1;
			this.editId = null
			this.getData()
		},
		detail(data){
			this.drawerConfig.show = true;
			this.drawerConfig.type = 3;
			this.editId = data
		},
		reset(){
			this.searchForm.name =''
			this.getData()
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData()
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				icon: "delete",
				onOk: ()=>{
					buildingDel({buildingIds:ids}).then(res=>{
						if(res.code === 200){
							this.$message.success(res.msg)
							this.getData()
						}else{
							this.$message.error(res.msg)
						}
					})
				},
			});
		},
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.tableChoosed);
			}
		},
		selectionChoosed(data) {
			this.tableChoosed = data;
		},
		//上传
		changeFile(data) {
			if(data.file.status == "done") {
				if(data.file.response.success == false) {
					this.$message.error(data.file.response.msg);
				} else {
					this.$message.success('操作成功');
					if(data.file.response.data) {
						this.uploadResult = data.file.response.data;
						this.uploadVisible = true;
					}
					this.getData();
				}
			}
		},
		//模版下载
		modelDownload() {
			window.open('https://saas.kaidalai.cn/excelModel/楼栋导入模版.xlsx');
		}
	},
};
</script>

<style lang="less" scoped>
.role-card{
  line-height: 30px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
</style>